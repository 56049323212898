// RESETS FOR ANGULAR MATERIAL
// This files is for global resets to the default values set by angular material.

// Don't change our font size!
.mat-mdc-card-subtitle,
.mat-mdc-card-content,
.mat-mdc-card-header .mat-mdc-card-title {
  font-size: inherit;
}

.mat-mdc-card-header {
  padding: 16px 16px 16px !important;
}
// We don't want to depend on * box-sizing rule.
// This means we have to add it manually where material expects it to be set
.mat-mdc-form-field-infix {
  box-sizing: inherit;
}

// Navigation items tooltips
.nav-tooltip {
  font-size: 1rem;
}

// Remove margin on expansion panels introduced with Angular Material 14
.mat-content.mat-content-hide-toggle {
  margin-right: 0 !important;
}

// This file must be imported in styles.scss or another global scss file, as it targets the snackbar (notification) via a class provided when opening the snackbar
// Only relevant when opening notifications as a component.
@import '/src/styles/cm-colors';
@import '/src/styles/breakpoints';

.warning-notification-snackbar {
  max-width: 32vw !important;
  min-width: 32vw !important;
  @media screen and (max-width: $md) {
    max-width: 90vw !important;
    min-width: 90vw !important;
  }

  .mdc-snackbar__surface {
    background: $cm-color-snackbar-warning !important;
    border: 2px solid $cm-color-snackbar-warning-border;

    .mat-mdc-snack-bar-label {
      color: $cm-color-snackbar-warning-border;
    }
  }
}

.info-notification-snackbar {
  max-width: 32vw !important;
  min-width: 32vw !important;
  @media screen and (max-width: $md) {
    max-width: 90vw !important;
    min-width: 90vw !important;
  }

  .mdc-snackbar__surface {
    background: $cm-color-snackbar-info !important;
    border: 2px solid $cm-color-snackbar-info-border;

    .mat-mdc-snack-bar-label {
      color: $cm-color-snackbar-info-border;
    }
  }
}

.success-notification-snackbar {
  max-width: 32vw !important;
  min-width: 32vw !important;
  @media screen and (max-width: $md) {
    max-width: 90vw !important;
    min-width: 90vw !important;
  }

  .mdc-snackbar__surface {
    background: $cm-color-snackbar-success !important;
    border: 2px solid $cm-color-snackbar-success-border;

    .mat-mdc-snack-bar-label {
      color: $cm-color-snackbar-success-border;
    }
  }
}

.error-notification-snackbar {
  max-width: 32vw !important;
  min-width: 32vw !important;
  @media screen and (max-width: $md) {
    max-width: 90vw !important;
    min-width: 90vw !important;
  }

  .mdc-snackbar__surface {
    background: $cm-color-snackbar-error !important;
    border: 2px solid $cm-color-snackbar-error-border;

    .mat-mdc-snack-bar-label {
      color: $cm-color-snackbar-error-border;
    }
  }
}

// CUSTOM THEME FOR ANGULAR MATERIAL
@use '@angular/material' as mat;
// We use a theme based on seges colors

@import './cm-colors.scss';

// Typography
$custom-typography: mat.m2-define-typography-config(
  $font-family: '"LFPressSans"',
  $body-1:
    mat.m2-define-typography-level(
      $font-size: 15px,
      $line-height: 1.5,
      $font-weight: 400,
    ),
  $button:
    mat.m2-define-typography-level(
      $font-size: 14px,
      $letter-spacing: 0.02em,
    ),
);

// Colors
$green-colors: (
  100: $cm-color-green-primary,
  300: $cm-color-green-secondary,
  500: $cm-color-green-3,
  700: $cm-color-green-4,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: $cm-color-white,
    100: $cm-color-white,
    200: $cm-color-white,
    300: $cm-color-white,
    400: $cm-color-white,
    500: $cm-color-white,
    600: $cm-color-text,
    700: $cm-color-text,
    800: $cm-color-text,
    900: $cm-color-text,
    A100: $cm-color-text,
    A200: $cm-color-text,
    A400: $cm-color-white,
    A700: $cm-color-text,
  ),
);

$orange-colors: (
  100: $cm-color-orange-1,
  300: $cm-color-orange-2,
  500: $cm-color-orange-3,
  700: $cm-color-orange-4,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: $cm-color-white,
    100: $cm-color-white,
    200: $cm-color-white,
    300: $cm-color-white,
    400: $cm-color-white,
    500: $cm-color-white,
    600: $cm-color-text,
    700: $cm-color-text,
    800: $cm-color-text,
    900: $cm-color-text,
    A100: $cm-color-text,
    A200: $cm-color-text,
    A400: $cm-color-white,
    A700: $cm-color-text,
  ),
);

$red-colors: (
  100: $cm-color-red-1,
  300: $cm-color-red-2,
  500: $cm-color-red-3,
  700: $cm-color-red-4,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: $cm-color-white,
    100: $cm-color-white,
    200: $cm-color-white,
    300: $cm-color-white,
    400: $cm-color-white,
    500: $cm-color-white,
    600: $cm-color-text,
    700: $cm-color-text,
    800: $cm-color-text,
    900: $cm-color-text,
    A100: $cm-color-text,
    A200: $cm-color-text,
    A400: $cm-color-white,
    A700: $cm-color-text,
  ),
);

//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.elevation-classes();
@include mat.app-background();
// Define the theme.
// mat-palette takes a base color palette, default color, light and dark variant
$app-primary: mat.m2-define-palette($green-colors, 100, 300, 100);
$app-accent: mat.m2-define-palette($orange-colors, 100, 300, 100);
$app-warn: mat.m2-define-palette($red-colors, 300, 500, 100);
$app-theme: mat.m2-define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.all-component-themes($app-theme);

/*Custom Dialog Css*/
.custom-dialog-container .mat-mdc-dialog-container {
  padding: 0;
}

.custom-dialog-container .mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0;
}

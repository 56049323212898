@import './breakpoints.scss';

// SETTING STYLE FOR CLASSES DEFINED IN SERVICES
// Since we can't include a stylesheet with a service, we add styling here
// Only add something here if you know what you are doing

// We set a custom backdrop class in the side-drawer-overlay service
.global-side-drawer-backdrop {
  background-color: $cm-color-black-overlay;
}

// We set a custom hidden class in the side-drawer-overlay service
.side-drawer-hidden {
  transition: 0.2s transform;
  transform: translateX(100%);
}

// We set a custom cdk-overlay-pane class as a help class to the side-drawer-hidden class
// ensures that the legend icon follows the SideDrawer in and out
.cdk-overlay-pane {
  transition: 0.2s transform;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  width: fit-content !important;
  max-width: 250px;
  min-width: 0;
}

// TooltipOverlayComponent
.tooltip-overlay {
  padding: 1rem;
  background-color: $cm-color-gray-2;
}

.tooltip-overlay-backdrop {
  background: none;
}

// Class added to large and medium dialogs when calling openMd() or openLg() on DialogService
.dialog-panel {
  overflow: auto;

  mat-mdc-dialog-container {
    background-color: $cm-color-gray-1;
    color: $cm-color-text;
    border-radius: 0;
    padding: 0;
    display: flex;
    padding: 24px;
  }
}

.add-water-and-soil-samples-dialog-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

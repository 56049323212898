// STYLING OF STANDARD HTML ELEMENTS
// Style here is applied to ALL elements, generally it is a bad idea to add things here.
@import './cm-colors.scss';

$field-icon-border-radius: 35%;

// Angular material variable overrides for the entire application
//? #{] tells the SCSS compiler to treat it as a variable and insert its value directly into the compiled CSS.
:root {
  --mdc-outlined-text-field-outline-color: #{$cm-color-gray-1};
  --mat-toolbar-container-background-color: #{$cm-color-gray-background};
  --mdc-filled-text-field-container-color: #{$cm-color-gray-background};
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-color: #{$cm-color-gray-3};
  --mat-expansion-header-text-size: 15px;
  --mat-option-label-text-size: 14px;
  --mat-form-field-container-text-size: 1rem;
  --mat-select-trigger-text-size: 14px;
  --mat-table-header-headline-color: #{$cm-color-black};
  --mat-body-color: #{$cm-color-black};
  --mdc-dialog-supporting-text-color: #{$cm-color-black};
  --mdc-plain-tooltip-supporting-text-size: 1rem;

  mat-card {
    padding: 12px !important;
  }
}

// STYLING OF ELEMENTS OUTSIDE THE NG-SCOPE
html {
  height: 100%;
}

// Setting rem base values.
@media only screen and (min-width: 0) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}

body {
  margin: 0;
  font-family: 'LFPressSans';
  line-height: 1.25;
  color: $cm-color-text;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed; // Disable bounce on Safari
  // Prevent screen from flashing blue on tap android
  -webkit-tap-highlight-color: transparent;
}

// ~ STYLING OF STANDARD ELEMENTS
a {
  color: $cm-color-orange;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

pre {
  font-family: 'LFPressSans';
  white-space: pre-wrap;
  tab-size: 4; /* Chrome 21+, Safari 6.1+, Opera 15+ */
}

//* apply this to dialog container panelClass property
.dialog-container-p-0 .mat-mdc-dialog-container {
  padding: 0;
}

.mat-mdc-tooltip {
  white-space: pre-line;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

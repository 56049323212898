@font-face {
  font-family: 'LFPressSans';
  src:
    url('/assets/fonts/LFPressSans.woff2') format('woff2'),
    url('/assets/fonts/LFPressSans.woff') format('woff'),
    url('/assets/fonts/LFPressSans.ttf') format('truetype'),
    url('/assets/fonts/LFPressSans.svg#LFPressSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LFPressSans';
  src:
    url('/assets/fonts/Crops-LFPressSans-Italic.woff2') format('woff2'),
    url('/assets/fonts/Crops-LFPressSans-Italic.woff') format('woff'),
    url('/assets/fonts/Crops-LFPressSans-Italic.ttf') format('truetype'),
    url('/assets/fonts/Crops-LFPressSans-Italic.svg#LFPressSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'LFPressSans';
  src:
    url('/assets/fonts/Crops-LFPressSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/Crops-LFPressSans-Bold.woff') format('woff'),
    url('/assets/fonts/Crops-LFPressSans-Bold.ttf') format('truetype'),
    url('/assets/fonts/Crops-LFPressSans-Bold.svg#LFPressSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.material-symbols-outlined-bold {
  font-variation-settings:
    'FILL' 0,
    'wght' 700,
    'GRAD' 0,
    'opsz' 48;
}

.material-symbols-outlined-small {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}

.material-symbols-outlined-large {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 64;
}

.material-symbols-outlined-grade {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 100,
    'opsz' 48;
}

.material-symbols-outlined-filled {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.material-symbols-outlined-sharp {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.material-symbols-outlined-rounded {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

.material-symbols-outlined-two-tone {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
}

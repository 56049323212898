//! Scroll to bottom for utility colors! Most used!

// theme greens
$cm-color-green-1: #005521; // Primary Green - color code inserted some places, remember to search replace all on change...
$cm-color-green-2: #20703f; // Secondary Green
$cm-color-green-3: #629a5c;
$cm-color-green-4: #88b47e;
$cm-color-green-5: #c4ddc7; // Hover Green
$cm-color-green-6: #e6f2ea; // Foundation Green
$cm-color-green-7: #356f4a;
$cm-color-green-8: #b1d6a8;
$cm-color-green-9: #d6e9d9;

$cm-color-green: $cm-color-green-1;

// saturated greens (for non cropmanager theme greens, i.e. status colors)
$cm-color-sat-green-1: #4fd94a;
$cm-color-sat-green-2: #43b05c;
$cm-color-sat-green-3: #41ad49;
$cm-color-sat-green-4: #25bd59;

$cm-color-sat-green: $cm-color-sat-green-2;

// blues
$cm-color-blue-1: #286577;
$cm-color-blue-2: #3a8ca7;
$cm-color-blue-3: #5aa9c4;
$cm-color-blue-4: #8cc7e1;

// skyes
$cm-color-sky: #eaf5fd;

// sands
$cm-color-sand-1: #c8c7b2;
$cm-color-sand-2: #d4d3c2;
$cm-color-sand-3: #e0e0d4;
$cm-color-sand-4: #f0f0e9;

$cm-color-sand: $cm-color-sand-4;

// yellows
$cm-color-yellow-1: #ffd800;
$cm-color-yellow-2: #f9e067;
$cm-color-yellow-3: #fbe999;
$cm-color-yellow-4: #fdf4c9;
$cm-color-yellow-5: #cb9700;
$cm-color-yellow-6: #fff8e3;

$cm-color-yellow: $cm-color-yellow-2;

// oranges
$cm-color-orange-1: #e95d0f;
$cm-color-orange-2: #dd8854;
$cm-color-orange-3: #e7ac82;
$cm-color-orange-4: #f2d3b9;
$cm-color-orange-5: #ff6e40;

$cm-color-orange: $cm-color-orange-1;

// reds
$cm-color-red-1: #e10630;
$cm-color-red-2: #f2464a;
$cm-color-red-3: #f98f82;
$cm-color-red-4: #fdcbbe;
$cm-color-red-5: #d04d52;
$cm-color-red-6: #faeded;

$cm-color-red: $cm-color-red-2;

//grays
$cm-color-gray-1: #f9f9f9; // background
$cm-color-gray-2: #eeeeee;
$cm-color-gray-3: #e5e5e5; // stroke
$cm-color-gray-4: #c8c8c8; // disabled text
$cm-color-gray-5: #737373; // secondary text
$cm-color-gray-6: #646464; // borders

$cm-color-gray: $cm-color-gray-3;

// whites
$cm-color-white: #ffffff;

// blacks
$cm-color-black: #101010;
$cm-color-black-2: #333333; // text color
$cm-color-black-3: #605e5c;

// box-shadows
$cm-box-shadow-1:
  0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
$cm-box-shadow-2:
  0 3px 3px -2px rgba(0, 0, 0, 0.2),
  0 3px 4px 0 rgba(0, 0, 0, 0.14),
  0 1px 8px 0 rgba(0, 0, 0, 0.12);
$cm-box-shadow-3:
  0 2px 4px -1px rgba(0, 0, 0, 0.2),
  0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 10px 0 rgba(0, 0, 0, 0.12);
$cm-box-shadow-4: 1px 1px 1px 0 #e4e4e4;
$cm-box-shadow-5: 0px 0px 10px 0px rgba(0, 0, 0, 0.1) inset;

$cm-box-shadow: $cm-box-shadow-1;

// transparents
$cm-color-white-overlay: rgba(255, 255, 255, 0.5);
$cm-color-white-opaque-bg: rgba(255, 255, 255, 0.8);
$cm-color-black-overlay: rgba(0, 0, 0, 0.5);

// * utility
$cm-color-green-primary: $cm-color-green-1;
$cm-color-green-secondary: $cm-color-green-2;
$cm-color-green-hover: $cm-color-green-5;
$cm-color-green-foundation: $cm-color-green-6;
$cm-color-green-select: $cm-color-green-7;
$cm-color-green-select-bg: rgba($cm-color-green-select, 0.1);

$cm-color-gray-background: $cm-color-gray-1;
$cm-color-gray-overview-background: #f1f1f1;
$cm-color-gray-stroke: $cm-color-gray-3;

$cm-color-text: $cm-color-black-2;
$cm-color-text-secondary: $cm-color-gray-5;
$cm-color-text-disabled: $cm-color-gray-4;

$cm-color-snackbar-info: $cm-color-sky;
$cm-color-snackbar-info-border: $cm-color-blue-1;
$cm-color-snackbar-success: $cm-color-green-6;
$cm-color-snackbar-success-border: $cm-color-sat-green-4;
$cm-color-snackbar-error: $cm-color-red-6;
$cm-color-snackbar-error-border: $cm-color-red-5;
$cm-color-snackbar-warning: $cm-color-yellow-6;
$cm-color-snackbar-warning-border: $cm-color-yellow-5;

$cm-color-draft: $cm-color-blue-4;
$color-warning-yellow-light-contrast: #ffb300;

$cm-color-overview-even-month: #dfe5f2;
$cm-color-overview-odd-month: #f3e4c5;
$cm-color-overview-even-month-today: #acc2f1;
$cm-color-overview-odd-month-today: #f1d7a3;

// * overview utilities (for farm-tasks-overview) here for now
$cm-util-minimal-card-height: 60px;
$cm-util-minimal-card-width: 120px;

$cm-util-compact-card-height: 30px;
$cm-util-compact-card-width: 120px;
